import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname.split("/")[1]);

  return (
    <div className="h-screen bg-primary">
      <div className="h-full flex flex-col gap-5 items-center justify-between p-5">
        <h1 className="text-white text-2xl font-bold mb-12">Admin</h1>

        <ul className="list-none w-full flex-1 flex flex-col gap-4">
          <Link
            to={"/home"}
            className={`w-full px-4 py-2 rounded border text-base text-white ${selected === "home" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
            onClick={() => setSelected("home")}>
            <li className="w-full">Accueil</li>
          </Link>

          <div className="w-full rounded-lg bg-white/10 px-2 py-1">
            <p className="text-white text-lg mb-2">Services</p>
            <div className="pl-1 border-l flex flex-col">
              <Link
                to={"/valorisation"}
                className={`w-full px-4 py-2 rounded text-base text-white ${selected === "valorisation" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
                onClick={() => setSelected("valorisation")}>
                <li className="w-full">Valorisation</li>
              </Link>
              <Link
                to={"/levee-service"}
                className={`w-full px-4 py-2 rounded text-base text-white ${selected === "levee-service" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
                onClick={() => setSelected("levee-service")}>
                <li className="w-full">Levée de fonds</li>
              </Link>
              <Link
                to={"/cession-service"}
                className={`w-full px-4 py-2 rounded text-base text-white ${selected === "cession-service" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
                onClick={() => setSelected("cession-service")}>
                <li className="w-full">Cession</li>
              </Link>
              <Link
                to={"/buyer-service"}
                className={`w-full px-4 py-2 rounded text-base text-white ${selected === "buyer-service" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
                onClick={() => setSelected("buyer-service")}>
                <li className="w-full">Reprise</li>
              </Link>
            </div>
          </div>

          <div className="w-full rounded-lg bg-white/10 px-2 py-1">
            <p className="text-white text-lg mb-2">Annonces</p>
            <div className="pl-1 border-l flex flex-col">
              <Link
                to={"/cession"}
                className={`w-full px-4 py-2 rounded text-base text-white ${selected === "cession" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
                onClick={() => setSelected("cession")}>
                <li className="w-full">Cession</li>
              </Link>
              <Link
                to={"/levee"}
                className={`w-full px-4 py-2 rounded text-base text-white ${selected === "levee" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
                onClick={() => setSelected("levee")}>
                <li className="w-full">Levée de fonds</li>
              </Link>
              <Link
                to={"/investisseur"}
                className={`w-full px-4 py-2 rounded text-base text-white ${selected === "investisseur" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
                onClick={() => setSelected("investisseur")}>
                <li className="w-full">Invest et Acquéreur</li>
              </Link>
            </div>
          </div>

          <div className="rounded-lg bg-white/10 px-2 py-1 flex flex-col">
            <Link
              to={"/discussion"}
              className={`w-full px-4 py-2 rounded text-base text-white ${selected === "discussion" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
              onClick={() => setSelected("discussion")}>
              <li className="w-full">Mise en relation</li>
            </Link>
            <Link
              to={"/user"}
              className={`w-full px-4 py-2 rounded text-base text-white ${selected === "user" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
              onClick={() => setSelected("user")}>
              <li className="w-full">Utilisateurs</li>
            </Link>
            <Link
              to={"/notation"}
              className={`w-full px-4 py-2 rounded text-base text-white ${selected === "notation" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
              onClick={() => setSelected("notation")}>
              <li className="w-full">Critères de notation</li>
            </Link>
            <Link
              to={"/question"}
              className={`w-full px-4 py-2 rounded text-base text-white ${selected === "question" ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
              onClick={() => setSelected("question")}>
              <li className="w-full">Questions</li>
            </Link>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
