import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";

const HEADER = [
  { title: "Type", key: "annonce_type" },
  { title: "Nom de l'annonce", key: "structure_name" },
  { title: "Date de création", key: "created_at" },
  { title: "Statut", key: "annonce_status" },
];

const INVESTOR_TYPE = {
  buyer: <p className="text-primary text-xs border border-primary p-1 rounded text-center">Acquéreur</p>,
  investor: <p className="text-yellow-600 text-xs border border-yellow-600  p-1 rounded text-center">Investisseur</p>,
};

const ANNONCE_STATUS = {
  DRAFT: <p className="text-gray-500 text-center">En cours de création</p>,
  PENDING: <p className="text-yellow-500 text-center">En attente</p>,
  ACCEPTED: <p className="text-green-500 text-center">Validé</p>,
  REJECTED: <p className="text-red-500 text-center">Refusé</p>,
};

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    searchTerm: searchParams.get("searchTerm") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 51,
    sort: { created_at: -1 },
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = {};
        if (filters.status) query.annonce_status = filters.status;
        if (filters.annonce_type) query.annonce_type = filters.annonce_type;
        if (filters.searchTerm) {
          if (filters.annonce_type === "buyer") query.buyer_title = filters.searchTerm;
          else if (filters.annonce_type === "investor") query.structure_name = filters.searchTerm;
          else {
            query.structure_name = filters.searchTerm;
            query.buyer_title = filters.searchTerm;
          }
        }
        if (filters.page) query.skip = (filters.page - 1) * filters.limit;
        if (filters.limit) query.limit = filters.limit;
        if (filters.sort) query.sort = filters.sort;

        setSearchParams(filters);

        const res = await api.post("/investor/search", query);
        if (!res.ok) throw res;
        setData(res.data);
        setTotal(res.total);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération des données");
      }
      setLoading(false);
    };

    fetchData();
  }, [filters]);

  return (
    <div className="p-8 space-y-3">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Aquéreurs/Investisseurs</h1>
        <p className="text-base text-gray-500">Voici la liste des aquéreurs et investisseurs</p>
      </header>
      <div className="flex justify-between items-center mt-8">
        <div className="flex gap-4">
          <select className="select" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
            <option value="">Tous</option>
            <option value="DRAFT">En cours de création</option>
            <option value="PENDING">En attente</option>
            <option value="ACCEPTED">Validé</option>
            <option value="REJECTED">Refusé</option>
          </select>
          <select className="select" value={filters.annonce_type} onChange={(e) => setFilters({ ...filters, annonce_type: e.target.value })}>
            <option value="">Tous</option>
            <option value="investor">Investisseurs</option>
            <option value="buyer">Acquéreurs</option>
          </select>
        </div>
        <button onClick={() => setShowCreateModal(true)} className="primary-button">
          Créer un investisseur/acquéreur
        </button>
      </div>
      <input
        type="text"
        placeholder="Rechercher un investisseur/acquéreur"
        className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        value={filters.searchTerm}
        onChange={(event) => setFilters((prevFilters) => ({ ...prevFilters, searchTerm: event.target.value }))}
      />
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full py-4 flex flex-col">
          <Table
            sort={filters.sort}
            onSort={(key) => {
              setFilters({ ...filters, sort: { [key]: filters.sort[key] === 1 ? -1 : 1 } });
            }}
            header={HEADER}
            loading={loading}
            noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucun résultat trouvé</div>}>
            {data.map((e, index) => (
              <tr
                onClick={() => navigate(`/investisseur/${e._id}`)}
                className="bg-white odd:bg-gray-50 hover:bg-gray-100 h-14 rounded-lg cursor-pointer border hover:border-gray-400"
                key={e._id}>
                <td className="p-2 max-w-xs truncate">{INVESTOR_TYPE[e.annonce_type]}</td>
                <td className="p-2 max-w-xs truncate">{e.annonce_type === "buyer" ? e.buyer_title : e.structure_name}</td>
                <td className="p-2 max-w-xs truncate">{new Date(e.created_at).toLocaleDateString("fr-FR")}</td>
                <td className="p-2 max-w-xs truncate">{ANNONCE_STATUS[e.annonce_status]}</td>
              </tr>
            ))}
          </Table>
        </section>
      )}
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </div>
  );
};

export default List;
