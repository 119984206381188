import React, { useState } from "react";
import toast from "react-hot-toast";

import { PDF_URL } from "../../../services/config";
import Switch from "../../../components/Switch";
import api from "../../../services/api";

const Report = ({ company, setCompany }) => {
  const [editing, setEditing] = useState(false);
  const [values, setValues] = useState({
    pdf_problem: company.pdf_problem,
    pdf_solution: company.pdf_solution,
    pdf_value_proposition: company.pdf_value_proposition,
    maturity_stage: company.maturity_stage,
    stage_percentage: company.stage_percentage,
    stage_of_concept: company.stage_of_concept,
    product_stage: company.product_stage,
    pdf_fundraising_date: company.pdf_fundraising_date,
    pdf_timeline: company.pdf_timeline,
    company_website: company.company_website,
    email: company.email,
    phone_number: company.phone_number,
    elevator_pitch: company.elevator_pitch,
  });

  const MATURITY_STAGE = ["Pre-seed", "Seed", "Early Growth", "Fast Growth", "Mature Stable"];
  const CONCEPT_STAGE = ["Recherche", "Tests", "Recherche de PMF", "PMF"];
  const PRODUCT_MATURITY = ["Idéation", "POC", "Prototype", "MVP", "Produit final"];

  const handlePublish = async () => {
    try {
      const res = await api.put(`/company/${company._id}`, values);
      if (!res.ok) throw res;
      toast.success("Les informations ont été mises à jour");
      setCompany(res.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de la mise à jour des informations");
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center gap-5 p-3 mt-2">
        <h1 className="text-xl font-semibold">Prévisualisation</h1>
        <Switch checked={editing} onChange={(v) => setEditing(v)} />
        <h1 className="text-xl font-semibold">Édition</h1>
        {editing && (
          <button className="primary-button w-fit" onClick={() => handlePublish()}>
            Enregistrer
          </button>
        )}
      </div>
      {editing ? (
        <div className="flex flex-between w-full p-2 space-x-3 rounded-t-lg">
          <div className="flex flex-col gap-5 w-[55%] my-2">
            <h1 className="text-2xl font-semibold mt-1 mb-3 text-red-500">Rapport de valorisation</h1>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Problème abordé</label>
              <textarea type="text" className="input" value={values.pdf_problem} onChange={(e) => setValues({ ...values, pdf_problem: e.target.value })} />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Solution développée</label>
              <textarea type="text" className="input" value={values.pdf_solution} onChange={(e) => setValues({ ...values, pdf_solution: e.target.value })} />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Proposition de valeur</label>
              <textarea type="text" className="input" value={values.pdf_value_proposition} onChange={(e) => setValues({ ...values, pdf_value_proposition: e.target.value })} />
            </div>

            <div className="flex flex-col justify-center gap-2 mt-5">
              <label className="text-red-500 text-xl">Attention ! Les 4 champs suivant risquent d'affecter la notation et la valorisation.</label>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Stade de maturité de la startup</label>
              <select
                className="select w-fit"
                value={values.maturity_stage}
                onChange={(e) => {
                  setValues({ ...values, maturity_stage: e.target.value });
                }}>
                {MATURITY_STAGE.map((stage) => (
                  <option key={stage} value={stage}>
                    {stage}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Pourcentage maturité de la startup</label>
              <input
                type="number"
                max="100"
                min="0"
                step={1}
                className="input w-fit"
                value={values.stage_percentage}
                onChange={(e) => {
                  setValues({ ...values, stage_percentage: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Stade de maturité du concept</label>
              <select
                className="select w-fit"
                value={values.stage_of_concept}
                onChange={(e) => {
                  setValues({ ...values, stage_of_concept: e.target.value });
                }}>
                {CONCEPT_STAGE.map((stage) => (
                  <option key={stage} value={stage}>
                    {stage}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Stade de maturité du produit</label>
              <select
                className="select w-fit"
                value={values.product_stage}
                onChange={(e) => {
                  setValues({ ...values, product_stage: e.target.value });
                }}>
                {PRODUCT_MATURITY.map((stage) => (
                  <option key={stage} value={stage}>
                    {stage}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-red-500 text-xl">----------------------------------------</label>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Date prévue de la levée de fond</label>
              <input
                type="text"
                className="input"
                value={values.pdf_fundraising_date}
                onChange={(e) => {
                  setValues({ ...values, pdf_fundraising_date: e.target.value });
                }}
              />
            </div>
            <div className="flex justify-between items-center">
              <label className="text-black text-xl">Timeline</label>
              <button className="primary-button" onClick={() => setValues({ ...values, pdf_timeline: [...values.pdf_timeline, { text: null, side: "left" }] })}>
                Ajouter
              </button>
            </div>
            <div className="flex flex-col items-start gap-8">
              {values.pdf_timeline.map((item, index) => (
                <div key={index} className="flex w-full flex-col gap-1">
                  <div className="flex justify-between items-center gap-3">
                    <input
                      type="text"
                      className="input"
                      value={item.title}
                      onChange={(e) => {
                        const newTimeline = [...values.pdf_timeline];
                        newTimeline[index].title = e.target.value;
                        setValues({ ...values, pdf_timeline: newTimeline });
                      }}
                    />

                    <select
                      className="select w-fit"
                      value={item.side}
                      onChange={(e) => {
                        const newTimeline = [...values.pdf_timeline];
                        newTimeline[index].side = e.target.value;
                        setValues({ ...values, pdf_timeline: newTimeline });
                      }}>
                      <option value="left">{"<--"}</option>
                      <option value="right">{"-->"}</option>
                    </select>
                    <select
                      className="select w-fit"
                      value={item.time}
                      onChange={(e) => {
                        const newTimeline = [...values.pdf_timeline];
                        newTimeline[index].time = e.target.value;
                        setValues({ ...values, pdf_timeline: newTimeline });
                      }}>
                      <option value="past">Passé</option>
                      <option value="future">Prévisionnel</option>
                    </select>
                    <button className="empty-button" onClick={() => setValues({ ...values, pdf_timeline: values.pdf_timeline.filter((_, i) => i !== index) })}>
                      Supprimer
                    </button>
                  </div>
                  <textarea
                    type="text"
                    className="input"
                    value={item.text}
                    onChange={(e) => {
                      const newTimeline = [...values.pdf_timeline];
                      newTimeline[index].text = e.target.value;
                      setValues({ ...values, pdf_timeline: newTimeline });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="h-full bg-primary w-1" />

          <div className="flex flex-col gap-5 w-[45%] m-2">
            <div className="flex flex-col justify-center gap-2">
              <h1 className="text-2xl font-semibold mt-1 mb-3 text-red-500">Certificat de notation</h1>
              <div className="flex flex-col justify-center gap-2">
                <label className="text-black text-xl">Site Internet</label>
                <input type="text" className="input" value={values.company_website} onChange={(e) => setValues({ ...values, company_website: e.target.value })} />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <label className="text-black text-xl">Email</label>
                <input type="text" className="input" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <label className="text-black text-xl">Téléphone</label>
                <input type="text" className="input" value={values.phone_number} onChange={(e) => setValues({ ...values, phone_number: e.target.value })} />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <label className="text-black text-xl">Elevator Pitch</label>
                <textarea type="text" className="input" value={values.elevator_pitch} onChange={(e) => setValues({ ...values, elevator_pitch: e.target.value })} maxLength={600} />
                {<p className="text-gray-500 text-sm">{values.elevator_pitch?.length || "0"}/600 charactères</p>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Preview company={company} />
      )}
    </div>
  );
};

const Preview = ({ company }) => {
  return (
    <div className="w-full overflow-visible flex justify-center">
      <iframe src={`${PDF_URL}/start-up/${company._id}`} className="border-2 border-black w-[1400px] h-[1684px] scale-75 origin-top-left p-0 m-0" />
    </div>
  );
};

export default Report;
