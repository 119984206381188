export const notation = (company, notations) => {
  const domains = {
    market_size: 0,
    market_size_total: 0,
    market_size_coef_total: 0,
    market_share: 0,
    market_share_total: 0,
    market_share_coef_total: 0,
    competitive_advantage: 0,
    competitive_advantage_total: 0,
    competitive_advantage_coef_total: 0,
    disruptive_tech: 0,
    disruptive_tech_total: 0,
    disruptive_tech_coef_total: 0,
    key_access: 0,
    key_access_total: 0,
    key_access_coef_total: 0,
    growth_strategy: 0,
    growth_strategy_total: 0,
    growth_strategy_coef_total: 0,
    team_skills: 0,
    team_skills_total: 0,
    team_skills_coef_total: 0,
    product_validation: 0,
    product_validation_total: 0,
    product_validation_coef_total: 0,
    tech_doability: 0,
    tech_doability_total: 0,
    tech_doability_coef_total: 0,
    tech_law_protection: 0,
    tech_law_protection_total: 0,
    tech_law_protection_coef_total: 0,
    financial_strength: 0,
    financial_strength_total: 0,
    financial_strength_coef_total: 0,
    strategy_backing: 0,
    strategy_backing_total: 0,
    strategy_backing_coef_total: 0,
    potential_impact: 0,
    potential_impact_total: 0,
    potential_impact_coef_total: 0,
    social_responsability: 0,
    social_responsability_total: 0,
    social_responsability_coef_total: 0,
  };

  for (const notation of notations) {
    const computes = executeNotation(company, notation);
    if (!computes) continue;
    Object.keys(computes).forEach((key) => {
      if (key === "points") return;
      if (computes[key] > 0) {
        const splitKey = key.split("_coef")[0];
        domains[splitKey + "_total"] += computes.points * computes[key];
        domains[splitKey + "_coef_total"] += computes[key];
      }
    });
  }

  hardCodedNotation(domains, company);

  Object.keys(domains).forEach((key) => {
    if (domains[key + "_coef_total"] > 0) {
      domains[key] = domains[key + "_total"] / domains[key + "_coef_total"];
    }
  });

  return domains;
};

export const executeNotation = (company, notation) => {
  const computes = {
    points: 0,
    market_size_coef: 0,
    market_share_coef: 0,
    competitive_advantage_coef: 0,
    disruptive_tech_coef: 0,
    key_access_coef: 0,
    growth_strategy_coef: 0,
    team_skills_coef: 0,
    product_validation_coef: 0,
    tech_doability_coef: 0,
    tech_law_protection_coef: 0,
    financial_strength_coef: 0,
    strategy_backing_coef: 0,
    potential_impact_coef: 0,
    social_responsability_coef: 0,
  };

  const answersPassed = {};
  notation.answers.forEach((answer) => {
    answersPassed[answer.question_key] = false;
  });

  for (const answer of notation.answers) {
    if (answersPassed[answer.question_key]) continue;

    let value = "Non défini";
    if (company) {
      if (company.hasOwnProperty(answer.question_key)) {
        value = company[answer.question_key];
      } else if (answer.question_key.includes(".")) {
        const keys = answer.question_key.split(".");
        if (keys.length === 2 && Array.isArray(company[keys[0]])) {
          for (const item of company[keys[0]]) {
            if (item.hasOwnProperty(keys[1])) {
              value = item[keys[1]];
              break;
            }
          }
        }
      }
    }

    const valuesToCheck = Array.isArray(value) ? value : [value];
    for (const val of valuesToCheck) {
      const typedAnswerValue = type(answer.question_type, answer.value);
      const typedValue = type(answer.question_type, val);

      if (typedValue === null) continue;
      const res = operate(val, typedAnswerValue, answer.operator);

      if (!res) continue;
      answersPassed[answer.question_key] = true;

      for (const action of answer.actions) {
        if (action.action === "exit") return computes;
        if (action.action === "points") computes.points = action.value;
        if (action.action === "coefs") computes[action.key] = action.value;
      }
      break;
    }
  }

  return computes;
};

const type = (type, value) => {
  if (type === "number") {
    const float = parseFloat(value);
    if (isNaN(float)) return null;
    return float;
  }
  if (type === "boolean") return value === "true";
  return value;
};

const operate = (value1, value2, operator) => {
  if (operator === "==") {
    if (value1 === value2) {
      return true;
    }
  } else if (operator === "!=") {
    if (value1 !== value2) {
      return true;
    }
  } else if (operator === "<") {
    if (value1 < value2) {
      return true;
    }
  } else if (operator === ">") {
    if (value1 > value2) {
      return true;
    }
  } else if (operator === "<=") {
    if (value1 <= value2) {
      return true;
    }
  } else if (operator === ">=") {
    if (value1 >= value2) {
      return true;
    }
  }
  return false;
};

const hardCodedNotation = (domains, company) => {
  // 29 - Nombre de fondateurs (selon le nombre d'ajout (+) de dirigeants dans le tableau)
  if (company.freelancers_count < (company.employee_count + company.trainee_count + company.freelancers_count) * 0.5) {
    domains.team_skills_total += 10 * 2;
    domains.team_skills_coef_total += 2;
  } else if (company.freelancers_count < (company.employee_count + company.trainee_count + company.freelancers_count) * 0.7) {
    domains.team_skills_total += 0 * 2;
    domains.team_skills_coef_total += 2;
  }

  // 197 - Runaway (selon le nombre de mois de runaway)
  if (company.is_profitable) {
    const runaway = company.cash_flow_4 / company.current_monthly_cash_burn;
    let points = 0;
    if (runaway < 2) points = 0;
    else if (runaway < 4) points = 3;
    else if (runaway < 5) points = 3;
    else if (runaway < 8) points = 7;
    else points = 10;
    domains.key_access_total += points * 2;
    domains.key_access_coef_total += 2;
    domains.financial_strength_total += points * 5;
    domains.financial_strength_coef_total += 5;
  }

  // 91 - La mise sur le marché de la solution requiert-elle des autorisations
  if (company.has_product_authorization_needs) {
    let a = 0,
      b = 0;
    if (company.refusal_rate === "0-10%") a += 0;
    else if (company.refusal_rate === "10-25%") a += 4;
    else if (company.refusal_rate === "25-50%") a += 8;
    else if (company.refusal_rate === ">50%") a += 10;

    if (company.authorization_timeline === "1 mois ou moins") b += 0;
    else if (company.authorization_timeline === "1 à 3 mois") b += 4;
    else if (company.authorization_timeline === "3 à 6 mois") b += 8;
    else if (company.authorization_timeline === "6 mois à 1 an") b += 9;
    else if (company.authorization_timeline === "Plus d'un an") b += 10;

    const points = 10 - (a * 3 + b) / 4;
    if (company.obtained_authorizations) {
      domains.tech_doability_total += 0;
      domains.tech_doability_coef_total += 0;
    } else {
      domains.tech_doability_total += points * 3;
      domains.tech_doability_coef_total += 3;
    }
  }

  // 92 - Précisez l'entité régulatrice dont vous vous répondez
  if (company.has_product_authorization_needs) {
    let a = 0,
      b = 0;
    if (company.refusal_rate === "0-10%") a += 0;
    else if (company.refusal_rate === "10-25%") a += 4;
    else if (company.refusal_rate === "25-50%") a += 8;
    else if (company.refusal_rate === ">50%") a += 10;

    if (company.authorization_timeline === "1 mois ou moins") b += 0;
    else if (company.authorization_timeline === "1 à 3 mois") b += 4;
    else if (company.authorization_timeline === "3 à 6 mois") b += 8;
    else if (company.authorization_timeline === "6 mois à 1 an") b += 9;
    else if (company.authorization_timeline === "Plus d'un an") b += 10;

    const points = (a * 3 + b) / 4;
    // TODO
  }

  let points_network_effects_presence = 0;
  let points_special_features_demand_intensity = 0;
  let points_target_customers_costs_multiple_use = 0;
  let points_is_easy_switch = 0;

  if (company.network_effects_presence === "Effets de réseaux faibles ou dans la norme") points_network_effects_presence = 0;
  else if (company.network_effects_presence === "Effets de réseaux marqués (Directs uniquement)") points_network_effects_presence = 7;
  else if (company.network_effects_presence === "Effets de réseaux marqués (Indirects uniquement)") points_network_effects_presence = 7;
  else if (company.network_effects_presence === "Effets de réseaux marqués (Directs et indirects)") points_network_effects_presence = 10;

  if (company.special_features_demand_intensity === "Faibles ou dans la norme") points_special_features_demand_intensity = 0;
  else if (company.special_features_demand_intensity === "Importantes") points_special_features_demand_intensity = 10;

  if (company.target_customers_costs_multiple_use === "Ils peuvent utiliser des solutions concurrentes sans réelle difficulté ou coût supplémentaire (ex : Facebook et Twitter)")
    points_target_customers_costs_multiple_use = 0;
  else if (
    company.target_customers_costs_multiple_use ===
    "Il existe un faible coût ou de maigres difficultés lors de l'utilisation de plusieurs solutions concurrentes (ex: Netflix et Disney Plus)"
  )
    points_target_customers_costs_multiple_use = 6;
  else if (
    company.target_customers_costs_multiple_use ===
    "Il existe un fort coût ou d'importantes difficultés lors de l'utilisation de plusieurs solutions concurrentes (Spotify et Deezer)"
  )
    points_target_customers_costs_multiple_use = 10;

  if (company.is_easy_switch === "Oui") points_is_easy_switch = 10;
  else if (company.is_easy_switch === "Non") points_is_easy_switch = 0;

  // 96 - Êtes-vous positionnés sur un segment de marché où les effets de réseaux sont forts ?
  let points_96 = (points_network_effects_presence + points_special_features_demand_intensity + points_target_customers_costs_multiple_use + points_is_easy_switch) / 4;
  domains.market_share_total += points_96 * 5;
  domains.market_share_coef_total += 5;

  // 97 - Quelle est l'intensité de la demande pour des fonctionnalités particulières sur votre segment de marché ?
  let points_97 = points_special_features_demand_intensity;
  let coef_97 = 0;

  if (company.competitors_size === "Principalement des acteurs de grande taille") coef_97 += 0;
  else if (company.competitors_size === "Equilibre entre des acteurs de petite et de grande taille") coef_97 += 2;
  else if (company.competitors_size === "Principalement des acteurs de petite taille") coef_97 += 3;

  if (company.current_position === "Pas encore sur le marché (0 % des parts de marché)") coef_97 += 0;
  else if (company.current_position === "Acteur de frange du marché (moins de 3 % des parts de marché)") coef_97 += 1;
  else if (company.current_position === "Acteur minoritaire du marché (4 à 10 % des parts de marché)") coef_97 += 1;
  else if (company.current_position === "Acteur très présent sur le marché (11 à 50 %)") coef_97 += 2;
  else if (company.current_position === "Acteur majoritaire sur le marché (>50 % des parts de marché)") coef_97 += 2;

  domains.market_size_total += points_97 * coef_97;
  domains.market_size_coef_total += coef_97;

  // 71 - Quelle est votre CLV ?

  if (company.clv_knowledge && company.cac_knowledge) {
    let points_71 = 0;
    const divide = company.clv_value / company.cac_value;
    if (divide > 5) points_71 = 10;
    else if (divide >= 3) points_71 = 8;
    else if (divide >= 2) points_71 = 4;
    else points_71 = 0;

    domains.competitive_advantage_total += points_71;
    domains.competitive_advantage_coef_total += 1;
    domains.growth_strategy_total += points_71 * 5;
    domains.growth_strategy_coef_total += 5;
    domains.product_validation_total += points_71 * 4;
    domains.product_validation_coef_total += 4;
  } else {
    domains.competitive_advantage_total += 0;
    domains.competitive_advantage_coef_total += 1;
    domains.growth_strategy_total += 0;
    domains.growth_strategy_coef_total += 5;
    domains.product_validation_total += 0;
    domains.product_validation_coef_total += 4;
  }

  // 56 - Indiquez la (les) proposition(s) de valeur identifiée(s)
  let points_business_costs_position = 0;
  let points_competitors_pricing_level = 0;
  if (company.business_costs_position === "Nous ne nous positionnons pas sur une compétitivité des prix") points_business_costs_position = -2;
  else if (company.business_costs_position === "Nous sommes parmi les moins chers du marché") points_business_costs_position = 2;
  else if (company.business_costs_position === "Nous sommes moins chers que la moyenne des acteurs de ce marché") points_business_costs_position = 1;
  else if (company.business_costs_position === "Nous sommes dans la moyenne des prix du marché") points_business_costs_position = -1;

  if (company.competitors_pricing_level === "Pas ou quasiment pas de concurrence par les prix") points_competitors_pricing_level = 0;
  else if (company.competitors_pricing_level === "Dans la norme - des solutions avec plusieurs niveaux de prix peuvent coexister") points_competitors_pricing_level = 1;
  else if (company.competitors_pricing_level === "Très forte concurrence par les prix - les prix ont tendance à être très rapprochés entre concurrents")
    points_competitors_pricing_level = 2;

  let points_56 = 0;
  if (points_business_costs_position === -2 && points_competitors_pricing_level === 0) points_56 = 0;
  else if (points_business_costs_position === -2 && points_competitors_pricing_level === 1) points_56 = 0;
  else if (points_business_costs_position === -2 && points_competitors_pricing_level === 2) points_56 = 0;
  else if (points_business_costs_position === 2 && points_competitors_pricing_level === 0) points_56 = 4;
  else if (points_business_costs_position === 2 && points_competitors_pricing_level === 1) points_56 = 8;
  else if (points_business_costs_position === 2 && points_competitors_pricing_level === 2) points_56 = 8;
  else if (points_business_costs_position === 1 && points_competitors_pricing_level === 0) points_56 = 5;
  else if (points_business_costs_position === 1 && points_competitors_pricing_level === 1) points_56 = 8;
  else if (points_business_costs_position === 1 && points_competitors_pricing_level === 2) points_56 = 10;
  else if (points_business_costs_position === -1 && points_competitors_pricing_level === 0) points_56 = 0;
  else if (points_business_costs_position === -1 && points_competitors_pricing_level === 1) points_56 = 1;
  else if (points_business_costs_position === -1 && points_competitors_pricing_level === 2) points_56 = 5;

  let coef_56_business_costs_position = 0;
  let coef_56_competitors_pricing_level = 0;

  let coef_56_competitive_advantage = 0;
  let coef_56_product_validation = 0;

  if (company.business_costs_position === "Nous ne nous positionnons pas sur une compétitivité des prix") coef_56_business_costs_position = -2;
  else if (company.business_costs_position === "Nous sommes parmi les moins chers du marché") coef_56_business_costs_position = 2;
  else if (company.business_costs_position === "Nous sommes moins chers que la moyenne des acteurs de ce marché") coef_56_business_costs_position = 1;
  else if (company.business_costs_position === "Nous sommes dans la moyenne des prix du marché") coef_56_business_costs_position = -1;

  if (company.competitors_pricing_level === "Pas ou quasiment pas de concurrence par les prix") coef_56_competitors_pricing_level = 0;
  else if (company.competitors_pricing_level === "Dans la norme - des solutions avec plusieurs niveaux de prix peuvent coexister") coef_56_competitors_pricing_level = 1;
  else if (company.competitors_pricing_level === "Très forte concurrence par les prix - les prix ont tendance à être très rapprochés entre concurrents")
    coef_56_competitors_pricing_level = 2;

  let coef_56 = coef_56_business_costs_position * coef_56_competitors_pricing_level;

  if (coef_56 === -4) {
    coef_56_business_costs_position = 3;
    coef_56_competitors_pricing_level = 2;
  } else if (coef_56 === -2) {
    coef_56_business_costs_position = 1;
    coef_56_competitors_pricing_level = 1;
  } else if (coef_56 === -1) {
    coef_56_business_costs_position = 1;
    coef_56_competitors_pricing_level = 0;
  } else if (coef_56 === 0) {
    coef_56_business_costs_position = 0;
    coef_56_competitors_pricing_level = 0;
  } else if (coef_56 === 1) {
    coef_56_business_costs_position = 2;
    coef_56_competitors_pricing_level = 0;
  } else if (coef_56 === 2) {
    coef_56_business_costs_position = 2;
    coef_56_competitors_pricing_level = 1;
  } else if (coef_56 === 4) {
    coef_56_business_costs_position = 4;
    coef_56_competitors_pricing_level = 3;
  }

  // 57 - Adéquation offre marché - macro algo

  let points_strong_market_pricing = 0;
  let points_cost_position = 0;

  if (company.cost_position === "Nous réduisons significativement le coût pour nos clients") points_cost_position = 2;
  else if (company.cost_position === "Nous réduisons légèrement le coût pour nos clients") points_cost_position = 1;
  else if (company.cost_position === "Nous nous positionnons avec un coût similaire aux solutions concurrentes les plus proches") points_cost_position = -1;
  else if (company.cost_position === "Nous ne nous positionnons pas sur une compétitivité des prix") points_cost_position = -2;

  if (company.strong_market_pricing === "Pas ou quasiment pas de concurrence par les prix") points_strong_market_pricing = 0;
  else if (company.strong_market_pricing === "Dans la norme - des solutions avec plusieurs niveaux de prix peuvent coexister") points_strong_market_pricing = 1;
  else if (company.strong_market_pricing === "Très forte concurrence par les prix - les prix ont tendance à être très rapprochés entre concurrents")
    points_strong_market_pricing = 2;

  let points_57 = 0;
  let product = points_cost_position * points_strong_market_pricing;

  if (product === -4) {
    points_57 = 0;
  } else if (product === -2) {
    points_57 = 4;
  } else if (product === -1) {
    points_57 = 5;
  } else if (product === 0) {
    points_57 = 0;
  } else if (product === 1) {
    points_57 = 8;
  } else if (product === 2) {
    points_57 = 8;
  } else if (product === 4) {
    points_57 = 10;
  }

  let coef_57_cost_position = 0;
  let coef_57_strong_market_pricing = 0;

  if (company.cost_position === "Nous réduisons significativement le coût pour nos clients") coef_57_cost_position = 2;
  else if (company.cost_position === "Nous réduisons légèrement le coût pour nos clients") coef_57_cost_position = 1;
  else if (company.cost_position === "Nous nous positionnons avec un coût similaire aux solutions concurrentes les plus proches") coef_57_cost_position = -1;
  else if (company.cost_position === "Nous ne nous positionnons pas sur une compétitivité des prix") coef_57_cost_position = -2;

  if (company.strong_market_pricing === "Pas ou quasiment pas de concurrence par les prix") coef_57_strong_market_pricing = 0;
  else if (company.strong_market_pricing === "Dans la norme - des solutions avec plusieurs niveaux de prix peuvent coexister") coef_57_strong_market_pricing = 1;
  else if (company.strong_market_pricing === "Très forte concurrence par les prix - les prix ont tendance à être très rapprochés entre concurrents")
    coef_57_strong_market_pricing = 2;

  let coef_57 = coef_57_cost_position * coef_57_strong_market_pricing;

  let coef_57_competitive_advantage = 0;
  let coef_57_product_validation = 0;

  if (coef_57 === -4) {
    coef_57_competitive_advantage = 3;
    coef_57_product_validation = 2;
  } else if (coef_57 === -2) {
    coef_57_competitive_advantage = 2;
    coef_57_product_validation = 1;
  } else if (coef_57 === -1) {
    coef_57_competitive_advantage = 1;
    coef_57_product_validation = 0;
  } else if (coef_57 === 0) {
    coef_57_competitive_advantage = 0;
    coef_57_product_validation = 0;
  } else if (coef_57 === 1) {
    coef_57_competitive_advantage = 1;
    coef_57_product_validation = 0;
  } else if (coef_57 === 2) {
    coef_57_competitive_advantage = 2;
    coef_57_product_validation = 1;
  } else if (coef_57 === 4) {
    coef_57_competitive_advantage = 4;
    coef_57_product_validation = 3;
  }

  domains.competitive_advantage_total += points_57 * coef_57_competitive_advantage;
  domains.competitive_advantage_coef_total += coef_57_competitive_advantage;

  domains.product_validation_total += points_57 * coef_57_product_validation;
  domains.product_validation_coef_total += coef_57_product_validation;

  // 202 - CA dernier exercice

  let points_turnover_4 = 0;

  if (company.turnover_4 < 10000) points_turnover_4 = 0;
  else if (company.turnover_4 < 30000) points_turnover_4 = 4;
  else if (company.turnover_4 < 100000) points_turnover_4 = 5;
  else if (company.turnover_4 < 500000) points_turnover_4 = 6;
  else if (company.turnover_4 < 1500000) points_turnover_4 = 7;
  else if (company.turnover_4 < 5000000) points_turnover_4 = 8;
  else if (company.turnover_4 < 10000000) points_turnover_4 = 9;
  else if (company.turnover_4 >= 10000000) points_turnover_4 = 10;

  let coef_202_market_share = 0;
  let coef_202_product_validation = 0;

  if (company.turnover_4 < 10000) {
    coef_202_market_share = 1;
    coef_202_product_validation = 1;
  } else if (company.turnover_4 < 30000) {
    coef_202_market_share = 1;
    coef_202_product_validation = 1;
  } else if (company.turnover_4 < 100000) {
    coef_202_market_share = 2;
    coef_202_product_validation = 2;
  } else if (company.turnover_4 < 500000) {
    coef_202_market_share = 2;
    coef_202_product_validation = 2;
  } else if (company.turnover_4 < 1500000) {
    coef_202_market_share = 3;
    coef_202_product_validation = 3;
  } else if (company.turnover_4 < 5000000) {
    coef_202_market_share = 4;
    coef_202_product_validation = 4;
  } else if (company.turnover_4 < 10000000) {
    coef_202_market_share = 4;
    coef_202_product_validation = 4;
  } else if (company.turnover_4 >= 10000000) {
    coef_202_market_share = 5;
    coef_202_product_validation = 5;
  }

  domains.market_share_total += points_turnover_4 * coef_202_market_share;
  domains.market_share_coef_total += coef_202_market_share;

  domains.product_validation_total += points_turnover_4 * coef_202_product_validation;
  domains.product_validation_coef_total += coef_202_product_validation;

  // 203 - Point mort d'exploitation

  let points_203 = 0;

  let coef_203_key_access = 2;
  let coef_203_financial_strength = 4;

  if (
    (company.operating_income_3 > 0 && company.operating_income_4 > 0 && company.operating_income_5 > 0) ||
    (company.operating_income_3 < 0 && company.operating_income_4 > 0 && company.operating_income_5 > 0) ||
    company.operating_income_5 > 0 ||
    (company.operating_income_3 < 0 && company.operating_income_4 < 0 && company.operating_income_5 < 0)
  ) {
    if (company.operating_income_3 > 0 && company.operating_income_4 > 0 && company.operating_income_5 > 0) {
      points_203 = 10;
    } else if (company.operating_income_3 < 0 && company.operating_income_4 > 0 && company.operating_income_5 > 0) {
      points_203 = 8;
    } else if (company.operating_income_5 > 0) {
      points_203 = 5;
    } else if (company.operating_income_3 < 0 && company.operating_income_4 < 0 && company.operating_income_5 < 0) {
      points_203 = 0;
    }
  } else {
    points_203 = 4;
  }

  domains.key_access_total += points_203 * coef_203_key_access;
  domains.key_access_coef_total += coef_203_key_access;

  domains.financial_strength_total += points_203 * coef_203_financial_strength;
  domains.financial_strength_coef_total += coef_203_financial_strength;

  // 204 - Ratio = CP / Endettement

  let result_204 = 0;

  if (company.amout_of_shareholders && company.debt) {
    result_204 = company.amout_of_shareholders / company.debt;
  } else result_204 = 0;

  let points_204 = 0;

  if (result_204 < 1) points_204 = 10;
  else if (result_204 < 1.3) points_204 = 6;
  else if (result_204 < 1.6) points_204 = 5;
  else if (result_204 < 2.5) points_204 = 2;
  else if (result_204 >= 2.5) points_204 = 0;

  domains.key_access_total += points_204 * 2;
  domains.key_access_coef_total += 2;

  // 205 - Adéquation compétences innovation

  let points_type_of_innovation = 0;
  let points_technical_experience = 0;

  if (company.type_of_innovation === "Technologique") {
    points_type_of_innovation = 1;
  }
  points_type_of_innovation = 0;

  if (company.technical_experience === "Pas d'expérience ou de compétences techniques particulières") {
    points_technical_experience = -1;
  } else if (company.technical_experience === "Formation technique sans expérience") {
    points_technical_experience = -1;
  } else if (company.technical_experience === "Expérience sur d'autres technologies durant au moins 6 mois") {
    points_technical_experience = -1;
  } else if (company.technical_experience === "Expérience sur d'autres technologies durant au moins 3 ans") {
    points_technical_experience = 1;
  } else if (company.technical_experience === "Expérience sur d'autres technologies durant au moins 7 ans") {
    points_technical_experience = 2;
  } else if (company.technical_experience === "Expérience sur le/les technologies principale(s) utilisées par la startup durant au moins 6 mois") {
    points_technical_experience = 1;
  } else if (company.technical_experience === "Expérience sur le/les technologies principale(s) utilisées par la startup durant au moins 3 ans") {
    points_technical_experience = 1;
  } else if (company.technical_experience === "Expérience sur le/les technologies principale(s) utilisées par la startup durant au moins 6 ans") {
    points_technical_experience = 3;
  }

  let points_205 = 0;
  let product_205 = points_type_of_innovation * points_technical_experience;

  if (product_205 === -1) {
    points_205 = 0;
  } else if (product_205 === 0) {
    points_205 = 0;
  } else if (product_205 === 1) {
    points_205 = 7;
  } else if (product_205 === 2) {
    points_205 = 8;
  } else if (product_205 === 3) {
    points_205 = 10;
  }

  let coef_205_growth_strategy = 0;
  let coef_205_tech_doability = 0;
  let coef_205_tech_law_protection = 0;

  if (points_205 === 0) {
    coef_205_growth_strategy = 0;
    coef_205_tech_doability = 0;
    coef_205_tech_law_protection = 0;
  }
  coef_205_growth_strategy = 3;
  coef_205_tech_doability = 5;
  coef_205_tech_law_protection = 1;

  domains.growth_strategy_total += points_205 * coef_205_growth_strategy;
  domains.growth_strategy_coef_total += coef_205_growth_strategy;

  domains.tech_doability_total += points_205 * coef_205_tech_doability;
  domains.tech_doability_coef_total += coef_205_tech_doability;

  domains.tech_law_protection_total += points_205 * coef_205_tech_law_protection;
  domains.tech_law_protection_coef_total += coef_205_tech_law_protection;

  // 206 - Majorité des actions dirigeants

  let points_206 = 0;
  let coef_206_growth_strategy = 1;
  let coef_206_key_access = 2;

  let majorityFounders = false;

  if (company.shareholders.length >= 1) {
    const totalShareholders = company.shareholders.length;
    if (totalShareholders > 0) {
      const numberOfFounders = company.shareholders.filter((shareholder) => shareholder.shareholder_profile === "Fondateur").length;
      majorityFounders = numberOfFounders > totalShareholders / 2;
    }
  }

  if (majorityFounders) {
    points_206 = 10;
  } else {
    points_206 = 0;
  }

  domains.growth_strategy_total += points_206 * coef_206_growth_strategy;
  domains.growth_strategy_coef_total += coef_206_growth_strategy;

  domains.key_access_total += points_206 * coef_206_key_access;
  domains.key_access_coef_total += coef_206_key_access;
};

export const getScoreLetter = (scores) => {
  const thresholds = [
    { threshold: 0, letter: "C" },
    { threshold: 1, letter: "CC" },
    { threshold: 1.8, letter: "CCC" },
    { threshold: 2.4, letter: "B" },
    { threshold: 2.8, letter: "BB" },
    { threshold: 3.4, letter: "BBB" },
    { threshold: 4.4, letter: "A" },
    { threshold: 5.4, letter: "AA" },
    { threshold: 6.6, letter: "AAA" },
    { threshold: 9.4, letter: "AAA+" },
  ];

  for (let i = thresholds.length - 1; i >= 0; i--) {
    if (scores >= thresholds[i].threshold) {
      return thresholds[i].letter;
    }
  }

  return "C";
};

export const calculateWeightedScore = (scores, weights) => {
  let weightedSum = 0;
  let totalWeight = 0;
  let maxWeightedSum = 0;

  for (const key in weights) {
    if (weights.hasOwnProperty(key) && scores.hasOwnProperty(key)) {
      weightedSum += scores[key] * weights[key];
      totalWeight += weights[key];
      maxWeightedSum += 10 * weights[key];
    }
  }

  const normalizedScore = (weightedSum / maxWeightedSum) * 10;
  return normalizedScore.toFixed(2);
};

export const calculatePotentialScore = (scores, weights) => {
  const potentialWeights = {
    market_size: weights.market_size,
    market_share: weights.market_share,
    competitive_advantage: weights.competitive_advantage,
    disruptive_tech: weights.disruptive_tech,
    key_access: weights.key_access,
    growth_strategy: weights.growth_strategy,
  };

  let weightedSum = 0;
  let totalWeight = 0;
  let maxWeightedSum = 0;

  for (const key in potentialWeights) {
    if (potentialWeights.hasOwnProperty(key) && scores.hasOwnProperty(key)) {
      weightedSum += scores[key] * potentialWeights[key];
      totalWeight += potentialWeights[key];
      maxWeightedSum += 10 * potentialWeights[key];
    }
  }

  const normalizedScore = (weightedSum / maxWeightedSum) * 10;
  return normalizedScore.toFixed(2);
};

export const calculateRiskScore = (scores, weights) => {
  const riskWeights = {
    team_skills: weights.team_skills,
    product_validation: weights.product_validation,
    tech_doability: weights.tech_doability,
    tech_law_protection: weights.tech_law_protection,
    financial_strength: weights.financial_strength,
    strategy_backing: weights.strategy_backing,
  };

  let weightedSum = 0;
  let totalWeight = 0;
  let maxWeightedSum = 0;

  for (const key in riskWeights) {
    if (riskWeights.hasOwnProperty(key) && scores.hasOwnProperty(key)) {
      weightedSum += scores[key] * riskWeights[key];
      totalWeight += riskWeights[key];
      maxWeightedSum += 10 * riskWeights[key];
    }
  }

  const normalizedScore = (weightedSum / maxWeightedSum) * 10;
  return normalizedScore.toFixed(2);
};

export const getWeights = (startupState) => {
  const weightConfigs = {
    pre_seed: {
      market_size: 0.25,
      market_share: 0.25,
      competitive_advantage: 0.2,
      disruptive_tech: 0.15,
      key_access: 0.1,
      growth_strategy: 0.05,
      team_skills: 0.3,
      product_validation: 0.25,
      tech_doability: 0.15,
      tech_law_protection: 0.05,
      financial_strength: 0.05,
      strategy_backing: 0.1,
    },
    seed: {
      market_size: 0.25,
      market_share: 0.25,
      competitive_advantage: 0.2,
      disruptive_tech: 0.1,
      key_access: 0.15,
      growth_strategy: 0.05,
      team_skills: 0.25,
      product_validation: 0.3,
      tech_doability: 0.1,
      tech_law_protection: 0.1,
      financial_strength: 0.1,
      strategy_backing: 0.15,
    },
    early_growth: {
      market_size: 0.2,
      market_share: 0.2,
      competitive_advantage: 0.1,
      disruptive_tech: 0.2,
      key_access: 0.1,
      growth_strategy: 0.05,
      team_skills: 0.25,
      product_validation: 0.25,
      tech_doability: 0.1,
      tech_law_protection: 0.1,
      financial_strength: 0.15,
      strategy_backing: 0.15,
    },
    fast_growth: {
      market_size: 0.15,
      market_share: 0.15,
      competitive_advantage: 0.2,
      disruptive_tech: 0.1,
      key_access: 0.25,
      growth_strategy: 0.15,
      team_skills: 0.2,
      product_validation: 0.25,
      tech_doability: 0.1,
      tech_law_protection: 0.1,
      financial_strength: 0.25,
      strategy_backing: 0.1,
    },
    mature: {
      market_size: 0.1,
      market_share: 0.1,
      competitive_advantage: 0.2,
      disruptive_tech: 0.1,
      key_access: 0.3,
      growth_strategy: 0.2,
      team_skills: 0.1,
      product_validation: 0.3,
      tech_doability: 0.1,
      tech_law_protection: 0.1,
      financial_strength: 0.35,
      strategy_backing: 0.05,
    },
  };

  switch (startupState) {
    case "Pre-seed":
      return weightConfigs.pre_seed;
    case "Seed":
      return weightConfigs.seed;
    case "Early growth":
      return weightConfigs.early_growth;
    case "Fast growth":
      return weightConfigs.fast_growth;
    case "Mature":
      return weightConfigs.mature;
    default:
      return weightConfigs.pre_seed;
  }
};
