import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";

const HEADER = [
  { title: "Demandeur", key: "user_from_first_name" },
  { title: "Personne contacté", key: "user_to_first_name" },
  { title: "Date de création", key: "created_at" },
  { title: "Statut", key: "status" },
];

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    status: searchParams.get("status") || "buyer_nda",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 50,
    sort: { created_at: -1 },
  });

  const [discussions, setDiscussions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = {};
        if (filters.status) query.status = filters.status;
        if (filters.page) query.skip = (filters.page - 1) * filters.limit;
        if (filters.limit) query.limit = filters.limit;
        if (filters.sort) query.sort = filters.sort;

        setSearchParams(filters);

        const res = await api.post("/discussion/search", query);
        if (!res.ok) throw res;
        setDiscussions(res.data);
        setTotal(res.total);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération des données");
      }
      setLoading(false);
    };

    fetchData();
  }, [filters]);

  return (
    <div className="p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Discussions</h1>
        <p className="text-base text-gray-500">Voici la liste des discussions</p>
      </header>
      <select
        className="mt-3 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        value={filters.status}
        onChange={(event) => setFilters((prevFilters) => ({ ...prevFilters, status: event.target.value }))}>
        <option value="">Toutes les statuts</option>
        <option value="buyer_nda">NDA a signer par l'acheteur</option>
        <option value="seller_nda">NDA a signer par le vendeur</option>
        <option value="ongoing">Discussion en cours</option>
        <option value="stopped">Discussion arrêtée</option>
      </select>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full py-4 flex flex-col">
          <Table
            sort={filters.sort}
            onSort={(key) => {
              setFilters({ ...filters, sort: { [key]: filters.sort[key] === 1 ? -1 : 1 } });
            }}
            header={HEADER}
            loading={loading}
            noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucun résultat trouvé</div>}>
            {discussions.map((discussion, index) => (
              <tr
                onClick={() => navigate(`/discussion/${discussion._id}`)}
                className="bg-white odd:bg-gray-50 hover:bg-gray-100 h-14 rounded-lg cursor-pointer border hover:border-gray-400"
                key={discussion._id}>
                <td className="p-2 max-w-xs truncate">
                  {discussion.user_from_first_name} {discussion.user_from_last_name}
                </td>
                <td className="p-2 max-w-xs truncate">
                  {discussion.user_to_first_name} {discussion.user_to_last_name}
                </td>
                <td className="p-2 max-w-xs truncate">{new Date(discussion.created_at).toLocaleDateString("fr-FR")}</td>
                <td className="p-2 max-w-xs truncate">
                  <span
                    className={`text-xs rounded-md text-center py-1 px-3 ${
                      discussion.status === "PENDING" ? "bg-yellow-300" : discussion.status === "ONGOING" ? "bg-green-300" : "bg-red-300"
                    }`}>
                    {discussion.status}
                  </span>
                </td>
              </tr>
            ))}
          </Table>
        </section>
      )}
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </div>
  );
};

export default List;
