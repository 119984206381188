import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import api from "../../services/api";
import Switch from "../../components/Switch";

const View = () => {
  const { id } = useParams();
  const [company, setCompany] = useState();
  const [user, setUser] = useState();
  const [view, setView] = useState("infos");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setCompany(res.data);

          const userRes = await api.get(`/user/${res.data.user_id}`);
          if (userRes.ok) setUser(userRes.data);
          else toast.error("Une erreur est survenue");
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const handleChange = async (values) => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (res.ok) {
        toast.success("La cession a bien été modifiée");
        setCompany(res.data);
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  if (!company || !user) return <></>;

  return (
    <div className="flex flex-col p-8">
      <header className="w-full mb-8">
        <h1 className="text-xl font-semibold text-black mb-2">
          Accompagnement Cession pour <span className="text-primary">{company.company_name}</span>
          {company.deleted_at && <span className="text-red-500 text-sm ml-2">Supprimée</span>}
        </h1>
      </header>

      <div className="flex justify-between items-end mb-4">
        <div className="flex gap-5">
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "infos" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("infos")}>
            Informations
          </button>
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "raw" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("raw")}>
            Données brutes
          </button>
        </div>

        <div className="flex justify-end items-end gap-12 mb-4">
          <div className="space-y-5">
            <label className="text-black text-sm font-semibold">Paiement</label>
            <div className="flex justify-center h-8">
              <Switch checked={company.cession_has_paid} onChange={(v) => handleChange({ cession_has_paid: v })} />
            </div>
          </div>
          <div className="space-y-2">
            <label className="text-black text-sm font-semibold">Plan</label>
            <select className="select" value={company.cession_plan} onChange={(e) => handleChange({ cession_plan: e.target.value })}>
              <option value="PITCH_DECK">Accompagnement sur la rédaction du dossier (900 €)</option>
              <option value="BP">Accompagnement stratégie financière (1,850 €)</option>
              <option value="DIAGNOSTIC">Diagnostic Cession (2,500 €)</option>
              <option value="RELATION">Mise en relation acquéreurs (400 €)</option>
            </select>
          </div>
          <div className="mb-1 ml-4">
            {company.deleted_at ? (
              <button type="button" className="primary-button" onClick={() => handleChange({ deleted_at: null })}>
                Restaurer
              </button>
            ) : (
              <button type="button" className="red-button" onClick={() => handleChange({ deleted_at: new Date() })}>
                Supprimer
              </button>
            )}
          </div>
        </div>
      </div>

      {view === "infos" ? (
        <div className="space-y-4">
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Entreprise</h1>

            <div className="grid grid-cols-2 gap-2">
              <p className="text-primary text-sm font-medium">
                Nom de l'entreprise: <span className="text-sm text-black">{company.company_name}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                SIREN: <span className="text-sm text-black">{company.company_siren}</span>
              </p>

              <p className="text-primary text-sm font-medium">
                Taille d'entreprise: <span className="text-sm text-black">{company.company_category}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Secteur d'activité: <span className="text-sm text-black">{company.company_activity}</span>
              </p>
            </div>
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Créée par</h1>

            <div className="grid grid-cols-2 gap-2">
              <p className="text-primary text-sm font-medium">
                Prénom: <span className="text-sm text-black">{user.first_name}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Nom: <span className="text-sm text-black">{user.last_name}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                E-mail: <span className="text-sm text-black">{user.email}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Numéro: <span className="text-sm text-black">{user.phone}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Créée le: <span className="text-sm text-black">{company.created_at?.toLocaleString("fr-FR")}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Dernière modification le: <span className="text-sm text-black">{company.updated_at?.toLocaleString("fr-FR")}</span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
          <pre className="text-white text-sm">{JSON.stringify(company, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default View;
