import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import { API_URL } from "../../services/config";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";

const STATUS = {
  DRAFT: <span className="text-xs text-gray-500">Brouillon</span>,
  PENDING: <span className="text-xs text-yellow-500">En attente</span>,
  ACCEPTED: <span className="text-xs text-green-500">Validée</span>,
  REJECTED: <span className="text-xs text-red-500">Refusée</span>,
};

const HEADER = [
  { title: "Statut", key: "annonce_status" },
  { title: "Titre de l'annonce", key: "annonce_title" },
  { title: "Créer par", key: "user_first_name" },
  { title: "Date de création", key: "created_at" },
];

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    status: searchParams.get("status") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 50,
    sort: { created_at: -1 },
  });

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = { annonce: true, annonce_type: "levee" };
        if (filters.status) query.annonce_status = filters.status;
        if (filters.page) query.skip = (filters.page - 1) * filters.limit;
        if (filters.limit) query.limit = filters.limit;
        if (filters.sort) query.sort = filters.sort;

        setSearchParams(filters);

        const res = await api.post("/company/search", query);
        if (!res.ok) throw res;
        setCompanies(res.data);
        setTotal(res.total);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération des données");
      }
      setLoading(false);
    };

    fetchData();
  }, [filters]);

  return (
    <div className="p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Annonces</h1>
        <p className="text-base text-gray-500">Voici la liste des annonces de levée</p>
      </header>
      <select
        className="select mt-3 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        value={filters.status}
        onChange={(e) => setFilters((prevFilters) => ({ ...prevFilters, status: e.target.value }))}>
        <option value="">Toutes les annonces</option>
        <option value="draft">En cours de création</option>
        <option value="pending">En attente</option>
        <option value="validated">Validé</option>
        <option value="rejected">Refusé</option>
      </select>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full py-4 flex flex-col">
          <Table
            sort={filters.sort}
            onSort={(key) => {
              setFilters({ ...filters, sort: { [key]: filters.sort[key] === 1 ? -1 : 1 } });
            }}
            header={HEADER}
            loading={loading}
            noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucun résultat trouvé</div>}>
            {companies.map((company, index) => (
              <tr
                onClick={() => navigate(`/levee/${company._id}`)}
                className="bg-white odd:bg-gray-50 hover:bg-gray-100 h-14 rounded-lg cursor-pointer border hover:border-gray-400"
                key={company._id}>
                <td className="p-2 max-w-xs truncate">{STATUS[company.annonce_status]}</td>
                <td className="p-2 max-w-xs truncate">{company.annonce_title}</td>
                <td className="p-2 max-w-xs truncate">
                  {company.user_first_name} {company.user_last_name}
                </td>
                <td className="p-2 max-w-xs truncate">{new Date(company.created_at).toLocaleDateString("fr-FR")}</td>
              </tr>
            ))}
          </Table>
        </section>
      )}
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </div>
  );
};

export default List;
