import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import Loader from "./Loader";

export default ({ header, sort, total, onSort, loading, children, fixed = false, sticky = false, height = "h-full", noResultRender }) => {
  if (total === 0 && !loading) return noResultRender || <div className="w-full px-3 py-5">No data found</div>;

  if (loading) {
    return (
      <div className="w-full flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className={`w-full rounded overflow-x-auto no-scrollbar ${height}`}>
      <table className={`w-full ${fixed ? "table-fixed" : ""} border-separate border-spacing-y-1`}>
        <thead className={`text-left ${sticky ? "sticky top-0 z-10 bg-white shadow-sm" : ""}`}>
          <tr className="border-b">
            {header.map((item, index) => {
              return (
                <th key={index} className={`p-2 hover:bg-gray-100 ${sort[item.key] && "bg-gray-50"}`} colSpan={item.colSpan || 1}>
                  <button
                    className={`flex items-center gap-2 ${item.position === "right" ? "justify-end" : item.position === "center" ? "justify-center" : "justify-start"}`}
                    onClick={() => {
                      if (!item.key) return;
                      if (!onSort) return;
                      onSort(item.key);
                    }}>
                    <h3 className="text-sm font-semibold">{item.title}</h3>
                    {item.key && (
                      <div className="h-4 flex flex-col justify-center">
                        {sort && sort[item.key] === 1 ? (
                          <>
                            <IoIosArrowUp className="text-black" />
                            <IoIosArrowDown className="text-gray-700" />
                          </>
                        ) : sort && sort[item.key] === -1 ? (
                          <>
                            <IoIosArrowUp className="text-gray-700" />
                            <IoIosArrowDown className="text-black" />
                          </>
                        ) : (
                          <>
                            <IoIosArrowUp className="text-gray-700" />
                            <IoIosArrowDown className="text-gray-700" />
                          </>
                        )}
                      </div>
                    )}
                  </button>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="relative">{children}</tbody>
      </table>
    </div>
  );
};
