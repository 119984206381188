import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import { API_URL } from "../../services/config";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";

const ROLES = {
  user: <p className="text-green-700 bg-green-100 border border-green-700 rounded font-medium text-center">Utilisateur</p>,
  admin: <p className="text-blue-700 bg-blue-100 border border-blue-700 rounded font-medium text-center">Admin</p>,
};

const HEADER = [
  { title: "Nom", key: "first_name" },
  { title: "E-mail", key: "email" },
  { title: "Rôle", key: "role" },
  { title: "Inscrit le", key: "created_at" },
  { title: "Dernière connexion", key: "last_login_at" },
  { title: "Actions" },
];

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    search: searchParams.get("search") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 100,
    sort: { last_login_at: -1 },
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = {};
        if (filters.search) query.search = filters.search;
        if (filters.page) query.skip = (filters.page - 1) * filters.limit;
        if (filters.limit) query.limit = filters.limit;
        if (filters.sort) query.sort = filters.sort;

        setSearchParams(filters);

        const res = await api.post("/admin/search", query);
        if (!res.ok) throw res;
        setUsers(res.data);
        setTotal(res.total);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération des données");
      }
      setLoading(false);
    };

    fetchData();
  }, [filters]);

  return (
    <div className="p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Utilisateurs</h1>
        <p className="text-base text-gray-500">Voici la liste des utilisateurs de Linkera</p>
      </header>
      <input
        type="text"
        placeholder="Rechercher un utilisateur"
        className="mt-3 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        value={filters.search}
        onChange={(event) => setFilters((prevFilters) => ({ ...prevFilters, search: event.target.value }))}
      />
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full py-4 flex flex-col">
          <Table
            sort={filters.sort}
            onSort={(key) => {
              setFilters({ ...filters, sort: { [key]: filters.sort[key] === 1 ? -1 : 1 } });
            }}
            header={HEADER}
            loading={loading}
            noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucun résultat trouvé</div>}>
            {users.map((user, index) => (
              <tr
                onClick={() => navigate(`/user/${user._id}`)}
                className="bg-white odd:bg-gray-50 hover:bg-gray-100 h-14 rounded-lg cursor-pointer border hover:border-gray-400"
                key={user._id}>
                <td className="p-2 max-w-xs truncate">
                  {user.first_name} {user.last_name}
                </td>
                <td className="p-2 max-w-xs truncate">{user.email}</td>
                <td className="p-2 max-w-xs truncate">{ROLES[user.role]}</td>
                <td className="p-2 max-w-xs truncate">{new Date(user.created_at).toLocaleDateString("fr-FR")}</td>
                <td className="p-2 max-w-xs truncate">{new Date(user.last_login_at).toLocaleDateString("fr-FR")}</td>
                <td className="p-2 max-w-xs truncate">
                  <a href={`${API_URL}/admin/loginas/${user._id}`} target="_blank" className="primary-button py-4">
                    Login as
                  </a>
                </td>
              </tr>
            ))}
          </Table>
        </section>
      )}
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </div>
  );
};

export default List;
