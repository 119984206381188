import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../services/api";
import Table from "../../components/Table";

const STATUS = {
  DRAFT: <span className="text-xs text-gray-600 border border-gray-600 bg-gray-100 px-2 py-1 rounded-md">Brouillon</span>,
  PENDING: <span className="text-xs text-yellow-600 border border-yellow-600 bg-yellow-100 px-2 py-1 rounded-md">En attente</span>,
  ACCEPTED: <span className="text-xs text-green-600 border border-green-600 bg-green-100 px-2 py-1 rounded-md">Validée</span>,
  REJECTED: <span className="text-xs text-red-600 border border-red-600 bg-red-100 px-2 py-1 rounded-md">Refusée</span>,
};

const HEADER = [
  { title: "Statut", key: "annonce_status" },
  { title: "Titre de l'annonce", key: "annonce_title" },
  { title: "Entreprise", key: "company_name" },
  { title: "Créer par", key: "user_first_name" },
  { title: "Date de création", key: "created_at" },
];

const List = () => {
  const [filters, setFilters] = useState({
    status: "",
    page: 1,
    pageSize: 50,
    sort: { created_at: -1 },
  });

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = { annonce: true, annonce_type: "cession" };
        if (filters.status) query.annonce_status = filters.status;
        if (filters.sort) query.sort = filters.sort;
        if (filters.page) query.skip = (filters.page - 1) * filters.pageSize;
        const res = await api.post("/company/search", query);
        if (res.ok) setCompanies(res.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [filters]);

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mt-8">
        <div className="flex gap-4">
          <select className="select" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
            <option value="">Toutes les annonces</option>
            <option value="draft">En cours de création</option>
            <option value="pending">En attente</option>
            <option value="validated">Validé</option>
            <option value="rejected">Refusé</option>
          </select>
        </div>
      </div>

      <section className="w-full py-4 flex flex-col">
        <Table
          sort={filters.sort}
          onSort={(key) => {
            setFilters({ ...filters, sort: { [key]: filters.sort[key] === 1 ? -1 : 1 } });
          }}
          header={HEADER}
          loading={loading}
          noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
          {companies.map((item, index) => (
            <tr
              onClick={() => navigate(`/cession/${item._id}`)}
              className="bg-white odd:bg-gray-50 hover:bg-gray-100 h-14 rounded-lg cursor-pointer border hover:border-gray-400"
              key={item._id}>
              <td className="p-2 max-w-xs">
                <div>{STATUS[item.annonce_status]}</div>
              </td>
              <td className="p-2 max-w-xs truncate">{item.annonce_title}</td>
              <td className="p-2 max-w-xs truncate">{item.company_name}</td>
              <td className="p-2 max-w-xs truncate">
                {item.user_first_name} {item.user_last_name}
              </td>
              <td className="p-2 max-w-xs truncate">{new Date(item.created_at).toLocaleDateString("fr-FR")}</td>
            </tr>
          ))}
        </Table>
      </section>
    </div>
  );
};

export default List;
