import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import CreateModal from "./components/CreateModal";
import EditModal from "./components/EditModal";

const HEADER = [
  { title: "Nom", key: "name" },
  { title: "Questions" },
  { title: "Total de points", key: "total_points" },
  { title: "Dernière modification", key: "updated_at" },
  { title: "Actions" },
];

const Notation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    search: searchParams.get("search") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 100,
    sort: { updated_at: -1 },
  });

  const [notationRules, setNotationRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (notationRules.length && searchParams.has("rule")) {
      const rule = notationRules.find((e) => e._id === searchParams.get("rule"));
      if (rule) setSelected(rule);
    }
  }, [notationRules, searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = {};
        if (filters.search) query.search = filters.search;
        if (filters.page) query.skip = (filters.page - 1) * filters.limit;
        if (filters.limit) query.limit = filters.limit;
        if (filters.sort) query.sort = filters.sort;

        setSearchParams(filters);

        const res = await api.post("/notation-rules/search", query);
        if (!res.ok) throw res;
        setNotationRules(res.data);
        setTotal(res.total);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération des données");
      }
      setLoading(false);
    };

    fetchData();
  }, [filters]);

  const handleOpenRule = (rule) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("rule", rule._id);
    setSearchParams(newSearchParams);
    setSelected(rule);
  };

  const handleCloseRule = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("rule");
    setSearchParams(newSearchParams);
    setSelected(null);
  };

  const handleChangeRule = (rule) => {
    const newRules = notationRules.map((e) => (e._id === rule._id ? rule : e));
    setNotationRules(newRules);
  };

  return (
    <div className="p-8">
      <EditModal isOpen={!!selected} onClose={handleCloseRule} rules={notationRules} notation={selected} setNotation={handleChangeRule} />
      <header className="w-full mb-8 flex justify-between">
        <div className="flex items-center">
          <h1 className="text-3xl font-semibold text-black mb-2 mr-4">Règles de notation</h1>
        </div>
        <CreateModal total={total} setData={setNotationRules} />
      </header>
      <input
        type="text"
        placeholder="Rechercher une règle"
        className="mt-3 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        value={filters.search}
        onChange={(event) => setFilters((prevFilters) => ({ ...prevFilters, search: event.target.value }))}
      />
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full py-4 flex flex-col">
          <Table
            sort={filters.sort}
            onSort={(key) => {
              setFilters({ ...filters, sort: { [key]: filters.sort[key] === 1 ? -1 : 1 } });
            }}
            header={HEADER}
            loading={loading}
            noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucun résultat trouvé</div>}>
            {notationRules.map((rule) => (
              <tr
                onClick={() => handleOpenRule(rule)}
                className="bg-white odd:bg-gray-50 hover:bg-gray-100 h-14 rounded-lg cursor-pointer border hover:border-gray-400"
                key={rule._id}>
                <td className="p-2 max-w-xs truncate">{rule.name}</td>
                <td className="p-2 max-w-xs truncate">{rule.answers.map((q) => q.question).join(", ")}</td>
                <td className="p-2 max-w-xs truncate">{rule.total_points}</td>
                <td className="p-2 max-w-xs truncate">{new Date(rule.updated_at).toLocaleDateString("fr-FR")}</td>
                <td className="p-2 max-w-xs truncate">
                  <button className="primary-button py-4">Edit</button>
                </td>
              </tr>
            ))}
          </Table>
        </section>
      )}
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </div>
  );
};

export default Notation;
