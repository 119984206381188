import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import { BiTrash } from "react-icons/bi";

const HEADER = [
  { title: "Type", key: "valuation_type" },
  { title: "Plan", key: "valuation_plan" },
  { title: "Nom de l'entreprise", key: "company_name" },
  { title: "Créer par", key: "user_first_name" },
  { title: "Date de création", key: "created_at" },
  { title: "Actions" },
];

const VALO_TYPE = {
  SME: <span className="text-xs text-green-500">TPE/PME</span>,
  "START-UP": <span className="text-xs text-yellow-500">Start-up</span>,
};
const VALO_PLAN = {
  ACCELERATED: <span className="text-xs text-green-500">Accéléré</span>,
  CERTIFIED: <span className="text-xs text-yellow-500">Certifié</span>,
  NOTATION: <span className="text-xs text-blue-500">Notation</span>,
};

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    search: searchParams.get("search") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 20,
    sort: { created_at: -1 },
    valuation_type: searchParams.get("valuation_type") || "",
  });

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = {
          valuation: true,
          skip: (filters.page - 1) * filters.limit,
          limit: filters.limit,
          sort: filters.sort,
          valuation_type: filters.valuation_type,
          search: filters.search,
        };

        setSearchParams(filters);

        const res = await api.post("/company/search", query);
        if (!res.ok) throw res;
        setCompanies(res.data);
        setTotal(res.total);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération des données");
      }
      setLoading(false);
    };

    fetchData();
  }, [filters]);

  const handleDelete = async (item) => {
    const confirm = window.confirm("Voulez-vous vraiment supprimer cette entreprise ?");
    if (!confirm) return;
    try {
      const res = await api.delete(`/company/${item._id}`);
      if (!res.ok) throw res;
      toast.success("Entreprise supprimée avec succès");
      setCompanies((prev) => prev.filter((company) => company._id !== item._id));
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la suppression de l'entreprise");
    }
  };

  return (
    <div className="p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">{total} entreprises en cours de valorisation</h1>
      </header>
      <div className="flex items-center gap-4">
        <select className="select w-48" value={filters.valuation_type} onChange={(e) => setFilters({ ...filters, valuation_type: e.target.value })}>
          <option value="">Tous</option>
          <option value="SME">TPE/PME</option>
          <option value="START-UP">Start-up</option>
        </select>
        <input
          type="text"
          placeholder="Rechercher une entreprise"
          className="input w-96"
          value={filters.search}
          onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full py-4 flex flex-col">
          <Table
            sort={filters.sort}
            onSort={(key) => {
              setFilters({ ...filters, sort: { [key]: filters.sort[key] === 1 ? -1 : 1 } });
            }}
            header={HEADER}
            loading={loading}
            noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucun résultat trouvé</div>}>
            {companies.map((item, index) => (
              <tr
                onClick={() => navigate(`/valorisation/${(item.valuation_type || "start-up").toLowerCase()}/${item._id}`)}
                className="bg-white odd:bg-gray-50 hover:bg-gray-100 h-14 rounded-lg cursor-pointer border hover:border-gray-400"
                key={item._id}>
                <td className="p-2 max-w-xs truncate">{VALO_TYPE[item.valuation_type] || <span className="text-xs text-red-500">Inconnu</span>}</td>
                <td className="p-2 max-w-xs truncate">{item.valuation_has_paid ? VALO_PLAN[item.valuation_plan] : <span className="text-xs text-gray-500">Non payé</span>}</td>
                <td className="p-2 max-w-xs truncate">{item.company_name}</td>
                <td className="p-2 max-w-xs truncate">
                  {item.user_first_name} {item.user_last_name}
                </td>
                <td className="p-2 max-w-xs truncate">{new Date(item.created_at).toLocaleDateString("fr-FR")}</td>
                <td className="p-2 max-w-xs truncate">
                  <button
                    type="button"
                    className="p-2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete(item);
                    }}>
                    <BiTrash className="text-red-500 cursor-pointer" />
                  </button>
                </td>
              </tr>
            ))}
          </Table>
        </section>
      )}
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </div>
  );
};

export default List;
